import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { gsap } from "../plugins/gsap";
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger";
import ProductTemplate from "./product-template";
import { GatsbyImage } from "gatsby-plugin-image";

gsap.registerPlugin(ScrollTrigger);

const LoadTesting = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageMobileTestingLanding(title: { eq: "Load Testing Page" } ){
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
          heroImage {
            gatsbyImageData(
              placeholder: NONE
              quality: 100
            )
            title
          }
          heroContent {
            raw
          }
          heroButtons {
            title
            url
          }
          customersTitle
          featuresTopPage {
            title
            id
            content {
              raw
            }
            image {
              gatsbyImageData(
                placeholder: NONE
                quality: 100
              )
              title
            }
            button {
              title
              url
            }
          }
          howDoesItWork {
            title
            content {
              raw
            }
            image {
              gatsbyImageData(
                placeholder: NONE
              )
              title
            }
          }
          featuresBottomPage {
            title
            id
            content {
              raw
            }
            image {
              gatsbyImageData(
                placeholder: NONE
              )
              title
            }
            button {
              title
              url
            }
          }
          recognition {
            title
            text
            badges {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
              )
            }
          }
          footer {
            title
            buttons {
                title
                url

            }
          }
        }
      }
    `
  );

  const title = data?.title || "";
  const description = data?.description || "";
  const thumbnail = data?.featuredImage?.resize?.src || "";
  const heroImage = data?.heroImage;
  const heroContent = data?.heroContent || "";
  const heroButtons = data?.heroButtons || "";
  const customersTitle = data?.customersTitle || "";
  const featuresTopPage = data?.featuresTopPage || [];
  const howDoesItWork = data?.howDoesItWork || "";
  const featuresBottomPage = data?.featuresBottomPage || [];
  const recognition = data?.recognition || {};
  const footer = data?.footer || {};

  return (
    <ProductTemplate
      location={location}
      title={title}
      description={description}
      thumbnail={thumbnail}
      heroContent={heroContent}
      heroButtons={heroButtons}
      headerImg={
        <div className='header-img load-header-img'>
          <GatsbyImage
            image={heroImage?.gatsbyImageData}
            alt={heroImage?.title}
          />
        </div>
      }
      customersTitle={customersTitle}
      featuresTopPage={featuresTopPage}
      howDoesItWork={howDoesItWork}
      featuresBottomPage={featuresBottomPage}
      recognition={recognition}
      footer={footer}
      className='load-testing'
    />
  )
}

export default LoadTesting
