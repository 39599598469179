import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => {
      return <h1 className="xl:text-6xl m-0" style={{ whiteSpace: 'pre', marginBottom: '1em' }}>{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return <h1 className="xl:text-3xl m-0">{children}</h1>;
    },
    [BLOCKS.HEADING_3]: (_node, children) => {
      return <h2 style={{ marginBottom: 0 }}>{children}</h2>;
    },

    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return <p className="xl:text-xl m-0">{children}</p>;
    },
  },
};

const Feature = ({
  img,
  content,
  button,
}) => (
  <section className='container' style={{ padding: 0 }}>
    <div className='max-w-lg' style={{ display: 'flex', justifyContent: 'center' }}>
      <GatsbyImage
        className={getImgClassName(img?.title)}
        image={img?.gatsbyImageData}
        alt={img?.title}
      />
    </div>
    <div className='max-w-lg'>
        <div className='text-section'>
        {content && renderRichText(content, options)}
        </div>
        <div className="feature-btn">
        {button}
      </div>  
    </div>
  </section>
);

export default Feature;

const getImgClassName = (title) => {
  switch (title) {
    case 'Feature image 2':
      return 'second-web-img';
    case 'Load testing feature img 1':
      return 'first-load-img';
    case 'Load testing feature img 2':
      return 'second-load-img';
    case 'Load testing feature img 3':
      return 'third-load-img';
    default:
      return '';
  }
}
