import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export const Recognition = (props) => {
  const { 
      title,
      text,
      badges = []
  } = props.recognition || {};

  return <div style={{ paddingBottom: 120 }}>
    <div style={{ padding: '60px 0' }}>
      <div className='divider'></div>
    </div>
    <p className='text-2xl' style={{ textAlign: 'center', marginBottom: 0 }}>{title}</p>
    <p className='text-2xl' style={{ textAlign: 'center', fontWeight: 'bold' }}>{text}</p>
    <div div style={{ display: 'grid', position: 'relative', marginTop: 24  }}>
    <div className='background-line'></div>
    <div className="g2-badges">
      <GatsbyImage
        image={badges[0]?.gatsbyImageData}
        alt={badges[0]?.title}
      />
    </div>
    </div>
</div>
}