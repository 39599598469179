import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PinkGradientGhostButton from "./pink-gradient-ghost-button";
import { useStaticQuery, graphql } from "gatsby";

export const FooterReadyFor = (props) => {

  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulFooterBackground {
          background {
            gatsbyImageData(
              placeholder: NONE
            )
          }
        }
      }
    `
  );

  const { title, buttons = [] } = props || {};

  return <div style={{ display: 'grid' }}>
    <GatsbyImage
      style={{ gridColumn: 1, gridRow: 1, maxWidth: '100%'  }}
      className='max-w-lg'
      image={data.background.gatsbyImageData}
      alt={title}
    />
    <div className="footer-content text-section">
      <h1 
        className="xl:text-6xl m-0" 
        style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', margin: '16px 0', position: 'relative' }}
      >
        {title}
      </h1>
      <div className="btn-section lg-btn" style={{ marginBottom: 16, justifyContent: 'center' }}>
        {buttons.map(({ title, url }, i) => (
            <PinkGradientGhostButton title={title} href={url} key={i}/>
        ))}
      </div>
    </div>
  </div>
}
