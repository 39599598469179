import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Container } from "../components/ui/grid"
import Layout from "../components/layout"
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import Customers from "../components/customers";
import { BLOCKS } from "@contentful/rich-text-types";
import { Recognition } from "../components/ui/recognition";
import { FooterReadyFor } from "../components/ui/footer-ready-for";
import BlueGradientGhostButton from "../components/ui/blue-gradient-ghost-button";
import PinkGradientGhostButton from "../components/ui/pink-gradient-ghost-button";
import Feature from "../components/ui/product-feature";

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => {
      return <h1 className="xl:text-6xl m-0 header-title" style={{ marginBottom: '1em' }}>{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return <h1 className="xl:text-3xl m-0">{children}</h1>;
    },
    [BLOCKS.HEADING_3]: (_node, children) => {
      return <h2 style={{ marginBottom: 0 }}>{children}</h2>;
    },

    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return <p className="xl:text-xl m-0">{children}</p>;
    },
  },
};

const ProductTemplate = (props) => {

  const {
    location,
    title,
    description,
    thumbnail,
    heroContent,
    heroButtons = [],
    headerImg,
    customersTitle,
    featuresTopPage = [],
    howDoesItWork,
    featuresBottomPage = [],
    recognition,
    footer,
    className = ''
  } = props;

  return (
    <Layout location={location}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet" />

      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <div className='new-pages-wrapper'>
        <div className='header-and-top-features'>
          <header id='header-and-top-features-first-hero-image-mobile-product'>
          <Container>
            <div className="text-and-buttons max-w-lg"> 
              <div className="text-section">
                {heroContent && renderRichText(heroContent, options)}
              </div>
              <div className="btn-section">
                {heroButtons.map(({ title, url }, i) => (
                  <PinkGradientGhostButton title={title} href={url} key={i}/>
                ))}
            </div>
            </div>
            {headerImg}
          </Container>
          </header>
          <div style={{ marginBottom: 80 }}>
          <p style={{ margin: '50px auto' }} className="mb-10 mt-5 text-lg text-center text-gray-light font-normal opacity-75">
            {customersTitle}
          </p>
            <Customers all/>
          </div>

          <div className={`features-container top ${className}`}>
            {featuresTopPage.map((item, i) => (
              <Feature
                key={i}
                img={item?.image}
                content={item?.content}
                button={item?.button && <PinkGradientGhostButton title={item.button.title} href={item.button.url}/>}
              />
            ))}
            {howDoesItWork && 
              <div className='container'>
                <GatsbyImage
                  image={howDoesItWork?.image?.gatsbyImageData}
                  alt={howDoesItWork?.image?.title}
                />
              </div>
            }
          </div>
        </div>

        <div className="features-container bottom">
        {featuresBottomPage.map((item, i) => 
              <Feature
                key={i}
                img={item?.image}
                button={item?.button && <BlueGradientGhostButton title={item.button.title} href={item.button.url}/>}
                content={item?.content}
            />
          )}
        </div>
        <Recognition
          recognition={recognition}
        />
        <FooterReadyFor
          title={footer?.title || ''}
          buttons={footer?.buttons || []} 
        />
      </div>
    </Layout>
  )
}

export default ProductTemplate
