import React from "react";

const BlueGradientGhostButton = (props) => {
  return props.href ? (
      <a className="gradient-blue-ghost-btn" href={props.href}>{props.title}</a>
  ) : (
      <button className="gradient-blue-ghost-btn" onClick={props.onClick}>{props.title}</button>
  );
}


export default BlueGradientGhostButton;
